import {useState, useEffect} from 'react';
import {
  AlertDialog,
  Badge,
  Box,
  HStack,
  Center,
  Container,
  Button,
  Spinner,
  Text,
  Skeleton,
  VStack,
} from 'native-base';
import {firestore, database} from '../firebase.js';
import '../styles.css';
import RouteMapModal from '../components/RouteMapModal';
import TripCard from '../components/TripCard';
import {useUser} from '../contexts/userContext';
import {BiSolidError, BiSolidSmile} from 'react-icons/bi';
import {useParams} from 'react-router-dom';
import EditTripInfoModal from '../components/EditTripInfoModal';
import RejectTripModal from '../components/RejectTripModal';
import getDepartmentColor from '../utils/getDepartmentColor';
import {useOrganization} from '../contexts/OrganizationContext.js';
import axios from 'axios';

export default function EmployeeTrips() {
  const [user, setUser] = useUser();
  const [organization] = useOrganization();
  const [trips, setTrips] = useState([]);
  const [expandedTrip, setExpandedTrip] = useState();
  const [tab, setTab] = useState(user.isFinance ? 'due' : 'pending');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [isRouteMapModalOpen, setIsRouteMapModalOpen] = useState(false);
  const [routeCoordinates, setRouteCoordinates] = useState();
  const {employeeId} = useParams();
  const [employee, setEmployee] = useState();
  const [isFetchingEmployee, setIsFetchingEmployee] = useState(true);
  const [isEditTripInfoModalOpen, setIsEditTripInfoModalOpen] = useState(false);
  const [tripToEdit, setTripToEdit] = useState();
  const [
    isConfirmMarkAllTripsPaidModalOpen,
    setIsConfirmMarkAllTripsPaidModalOpen,
  ] = useState();
  const [isDeleteTripModalOpen, setIsDeleteTripModalOpen] = useState(false);
  const [tripToDelete, setTripToDelete] = useState(false);
  const [lastTripInCursor, setLastTripInCursor] = useState();
  const [isRejectTripModalVisible, setIsRejectTripModalVisible] =
    useState(false);
  const [tripToReject, setTripToReject] = useState(false);
  const [hasLoadedAllTrips, setHasLoadedAllTrips] = useState(false);
  const [isFetchingMoreTrips, setIsFetchingMoreTrips] = useState(false);
  const [tripsStatus, setTripsStatus] = useState([]);
  const [removedTrips, setRemovedTrips] = useState([]);

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTrips([]);
    setHasLoadedAllTrips(false);

    let updatedStatus = [];
    if (tab === 'due') {
      updatedStatus = organization?.settings.isModeratorEnabled
        ? ['approved_by_moderator']
        : ['approved', 'approved_by_moderator'];
    } else if (tab === 'paid') {
      updatedStatus = ['paid'];
    } else if (tab === 'approved') {
      updatedStatus = user.isModerator
        ? ['approved_by_moderator', 'paid']
        : ['approved', 'approved_by_moderator', 'paid'];
    } else {
      updatedStatus = user.isModerator
        ? ['approved', 'rejected']
        : ['pending_approval', 'rejected'];
    }
    user.setTripsListener(
      {
        status: updatedStatus,
        department: user.department,
        employeeId,
      },
      tripsSetter,
      errorHandler,
    );

    setTripsStatus(updatedStatus);

    return () => {
      user.unsubscribeListeners();
    };
  }, [tab]);

  const fetchMoreTrips = () => {
    setIsFetchingMoreTrips(true);
    return user.setTripsListener(
      {
        status: tripsStatus,
        department: user.department,
        previousLastTrip: lastTripInCursor,
        employeeId,
      },
      tripsSetter,
      errorHandler,
    );
  };

  const tripsSetter = value => {
    if (value.length < 10) {
      setHasLoadedAllTrips(true);
    }

    setTrips(prevTrips => {
      const trips = {};
      prevTrips.forEach(trip => {
        if (
          tripsStatus.includes(trip.status) &&
          !removedTrips.includes(trip.id)
        ) {
          trips[trip.id] = trip;
        }
      });
      value.forEach(trip => {
        trips[trip.id] = trip;
      });
      return Object.values(trips);
    });

    if (value.length) {
      setLastTripInCursor(value[value.length - 1].submitTime);
      setIsFetchingMoreTrips(false);
    } else {
      setLastTripInCursor();
    }
    setIsFetchingMoreTrips(false);
    setIsLoading(false);
  };

  const errorHandler = value => {
    setError(value);
    setIsFetchingMoreTrips(false);
    setIsLoading(false);
  };

  const fetchEmployeeDetails = async () => {
    setIsFetchingEmployee(true);
    const employeeDoc = await firestore
      .collection('employees')
      .doc(employeeId)
      .get();
    setEmployee(employeeDoc.data());
    setIsFetchingEmployee(false);
  };

  const getTotalPaymentDue = () => {
    let sum = 0;
    trips
      .filter(trip =>
        ['approved', 'approved_by_moderator'].includes(trip.status),
      )
      .forEach(trip => {
        sum += trip.getTotalAllowance();
      });
    return sum;
  };

  const toggleExpandedTrip = trip => {
    console.log(trip.id);
    if (expandedTrip && expandedTrip.id === trip.id) {
      setExpandedTrip('');
    } else {
      setExpandedTrip(trip);
    }
  };

  const confirmMarkAllTripsPaid = () => {
    setIsConfirmMarkAllTripsPaidModalOpen(true);
  };

  const markAllTripsAsPaid = () => {
    const batch = firestore.batch();
    trips.forEach(trip => {
      batch.update(firestore.collection('trips').doc(trip.id), {
        status: 'paid',
      });
    });
    batch.commit();
    setIsConfirmMarkAllTripsPaidModalOpen(false);
  };

  const openRouteMapModal = async trip => {
    setIsRouteMapModalOpen(true);
    if (trip.startTime.toDate() > new Date('2024-09-18')) {
      try {
        const response = await axios.get(`https://ec2.tourexpe.com/getRoute/${trip.id}`);

        if (response.data) {
          const updatedRouteCoordinates = response.data.map(element => ({
            latitude: element.coords.latitude,
            longitude: element.coords.longitude,
            heading: element.coords.heading,
            timestamp: element.timestamp,
            odometer: element.odometer,
            battery: element.battery,
          }));

          setRouteCoordinates(updatedRouteCoordinates);
        } else {
          setRouteCoordinates([]);
        }
      } catch (error) {
        console.error('Error fetching route coordinates:', error);
        setRouteCoordinates([]);
      }
    } else {
      const routeRef = database.ref(`tripsRoutes/${trip.id}/routeCoordinates`);
      const snapshot = await routeRef.once('value');
      const routeData = snapshot.val();

      if (routeData) {
        const updatedRouteCoordinates = Object.values(routeData).map(element => ({
          latitude: element.latitude,
          longitude: element.longitude,
          heading: element.heading,
          timestamp: element.timestamp,
          odometer: element.odometer,
          battery: element.battery,
        }));
        setRouteCoordinates(updatedRouteCoordinates);
      } else {
        setRouteCoordinates([]);
      }
    }
  };

  const openEditTripInfoModal = async trip => {
    setTripToEdit(trip);
    setIsEditTripInfoModalOpen(true);
  };

  const openRejectTripModal = async trip => {
    setTripToReject(trip);
    setIsRejectTripModalVisible(true);
  };

  const openDeleteTripModal = async trip => {
    setTripToDelete(trip);
    setIsDeleteTripModalOpen(true);
  };

  const deleteTrip = async () => {
    await firestore.collection('trips').doc(tripToDelete.id).delete();
    setRemovedTrips(prev => [...prev, tripToDelete.id]);
    setIsDeleteTripModalOpen(false);
  };

  return (
    <Center>
      <Container
        w="full"
        maxWidth="896px"
        alignItems="center"
        justifyContent="center"
        position="relative">
        <VStack
          w="full"
          bg="white"
          zIndex="1"
          borderColor="coolGray.200"
          borderBottomWidth="1"
          alignItems="center"
          position="sticky"
          top="0"
          space="2"
          py="2">
          <Container w="full" maxWidth="896px">
            <HStack
              w="full"
              justifyContent="space-between"
              alignItems="baseline"
              px="4"
              pt="2"
              pb="4"
              borderColor="coolGray.200"
              borderBottomWidth="1">
              {!isFetchingEmployee ? (
                <VStack>
                  <HStack space="2" alignItems="center">
                    <Text fontSize="xl" bold>
                      {employee.name}
                    </Text>
                    <Badge
                      variant="solid"
                      colorScheme={getDepartmentColor(employee.department)}
                      rounded="full">
                      {employee.department}
                    </Badge>
                  </HStack>
                  <Text>Phone no.: {employee.phoneNumber}</Text>
                </VStack>
              ) : (
                <VStack>
                  <Skeleton h="4" w="64" />
                  <Skeleton h="4" w="64" />
                </VStack>
              )}
              {/* {user.isFinance && (
                <VStack alignItems="end" space="2">
                  <HStack space="2" justifyContent="center">
                    <Text fontSize="xl">Total payment due:</Text>
                    <Text fontSize="xl" bold>
                      ₹{getTotalPaymentDue().toFixed(2)}
                    </Text>
                  </HStack>
                  <Button
                    isDisabled={isLoading}
                    onPress={confirmMarkAllTripsPaid}>
                    <Text color="white" bold>
                      Mark all trips as Paid
                    </Text>
                  </Button>
                </VStack>
              )} */}
            </HStack>
          </Container>

          {user.isFinance ? (
            <Container w="full" maxWidth="896px">
              <HStack w="full" space="8" justifyContent="center" px="4">
                <Button
                  size="lg"
                  colorScheme="success"
                  flex="1"
                  borderColor="success.500"
                  variant={tab === 'due' ? 'solid' : 'outline'}
                  rounded="full"
                  onPress={() => setTab('due')}>
                  Due
                </Button>
                <Button
                  size="lg"
                  colorScheme="success"
                  flex="1"
                  borderColor="success.500"
                  variant={tab === 'paid' ? 'solid' : 'outline'}
                  rounded="full"
                  onPress={() => setTab('paid')}>
                  Paid
                </Button>
              </HStack>
            </Container>
          ) : (
            <Container w="full" maxWidth="896px">
              <HStack w="full" space="8" justifyContent="center" px="4">
                <Button
                  size="lg"
                  colorScheme="success"
                  flex="1"
                  borderColor="success.500"
                  variant={tab === 'pending' ? 'solid' : 'outline'}
                  rounded="full"
                  onPress={() => setTab('pending')}>
                  Pending
                </Button>
                <Button
                  size="lg"
                  colorScheme="success"
                  flex="1"
                  borderColor="success.500"
                  variant={tab === 'approved' ? 'solid' : 'outline'}
                  rounded="full"
                  onPress={() => setTab('approved')}>
                  Approved
                </Button>
              </HStack>
            </Container>
          )}
        </VStack>
        <VStack w="full">
          {error ? (
            <Box
              w="full"
              borderColor="danger.400"
              borderWidth="1"
              rounded="lg"
              h="48"
              alignItems="center"
              p="6">
              <VStack justifyContent="center" h="full" alignItems="center">
                <Text color="danger.400">
                  <BiSolidError size="64" />
                </Text>
                <Text
                  color="danger.400"
                  style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}
                  textAlign="center">
                  An error has occurred.
                  <br />
                  {error.message}
                </Text>
              </VStack>
            </Box>
          ) : (
            <VStack w="full" space="4" mt="4">
              {isLoading ? (
                <Center w="full" h="full">
                  <Spinner size="lg" />
                </Center>
              ) : trips.length === 0 ? (
                <Box
                  w="full"
                  borderColor="coolGray.400"
                  borderWidth="1"
                  rounded="lg"
                  h="48"
                  alignItems="center"
                  p="6">
                  <VStack justifyContent="center" h="full" alignItems="center">
                    <Text color="coolGray.300">
                      <BiSolidSmile size="64" />
                    </Text>
                    <Text color="coolGray.400" textAlign="center">
                      {user.isFinance
                        ? tab === 'paid'
                          ? 'No trips have been paid.'
                          : 'No pending trips.'
                        : tab === 'approved'
                        ? 'No trips have been approved.'
                        : 'No trips are pending.'}
                    </Text>
                  </VStack>
                </Box>
              ) : (
                <VStack space="2">
                  {trips.map(trip => {
                    return (
                      <TripCard
                        trip={trip}
                        setRemovedTrips={setRemovedTrips}
                        isExpanded={expandedTrip && trip.id === expandedTrip.id}
                        setIsExpanded={() => toggleExpandedTrip(trip)}
                        showEmployeeDetails={false}
                        openRouteMapModal={openRouteMapModal}
                        openEditTripInfoModal={openEditTripInfoModal}
                        openRejectTripModal={openRejectTripModal}
                        openDeleteTripModal={openDeleteTripModal}
                        key={trip.id}
                      />
                    );
                  })}

                  {!hasLoadedAllTrips && (
                    <Center py="2">
                      <Button
                        isLoading={isFetchingMoreTrips}
                        onPress={() => fetchMoreTrips(lastTripInCursor)}>
                        Load more
                      </Button>
                    </Center>
                  )}
                </VStack>
              )}
            </VStack>
          )}
        </VStack>
      </Container>

      <RouteMapModal
        trip={expandedTrip}
        routeCoordinates={routeCoordinates}
        setRouteCoordinates={setRouteCoordinates}
        isRouteMapModalOpen={isRouteMapModalOpen}
        setIsRouteMapModalOpen={setIsRouteMapModalOpen}
      />

      <EditTripInfoModal
        trip={tripToEdit}
        isEditTripInfoModalOpen={isEditTripInfoModalOpen}
        setIsEditTripInfoModalOpen={setIsEditTripInfoModalOpen}
      />

      <RejectTripModal
        trip={tripToReject}
        isRejectTripModalVisible={isRejectTripModalVisible}
        setIsRejectTripModalVisible={setIsRejectTripModalVisible}
      />

      <AlertDialog
        useRNModal={true}
        isOpen={isConfirmMarkAllTripsPaidModalOpen}
        onClose={() => setIsConfirmMarkAllTripsPaidModalOpen(false)}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Are you sure?</AlertDialog.Header>
          <AlertDialog.Body>
            <Text>
              Are you sure you want to mark all {trips.length} trips as paid
              with ₹{getTotalPaymentDue().toFixed(2)}?
            </Text>
          </AlertDialog.Body>
          <HStack w="full" justifyContent="flex-end" p="4" pt="0">
            <Button onPress={markAllTripsAsPaid} minW="24">
              Yes
            </Button>
          </HStack>
        </AlertDialog.Content>
      </AlertDialog>
      <AlertDialog
        useRNModal={true}
        isOpen={isDeleteTripModalOpen}
        onClose={() => setIsDeleteTripModalOpen(false)}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Confirm delete</AlertDialog.Header>
          <AlertDialog.Body>
            <Text>
              Are you sure you want to delete this trip?
              <br />
              {tripToDelete
                ? `${tripToDelete.user.name} - ${tripToDelete.getTripDate()}`
                : ''}
            </Text>
          </AlertDialog.Body>
          <HStack w="full" justifyContent="flex-end" p="4" pt="0">
            <Button onPress={deleteTrip} colorScheme="danger" minW="24">
              Delete
            </Button>
          </HStack>
        </AlertDialog.Content>
      </AlertDialog>
    </Center>
  );
}
